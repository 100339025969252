<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">budget documents</div>
      </v-row>
      <v-row class="">
        <div class="">
          View all the budget documents for this financial year.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-list class="text-capitalize" subheader>
        <v-list-item
          link
          v-for="item in budgetItems1"
          :key="item.title"
          @click="getDocuments(item)"
        >
          <v-list-item-icon>
            <v-icon color="primary"> {{ item.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-title> {{ item.title }} </v-list-item-title>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-subheader inset>more documents</v-subheader>
        <v-list-item
          link
          v-for="item in budgetItems2"
          :key="item.title"
          @click="getDocuments(item)"
        >
          <v-list-item-icon>
            <v-icon color="primary"> {{ item.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-title> {{ item.title }} </v-list-item-title>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-subheader inset>more documents</v-subheader>
        <v-list-item
          link
          v-for="item in budgetItems3"
          :key="item.title"
          @click="getDocuments(item)"
        >
          <v-list-item-icon>
            <v-icon color="primary"> {{ item.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-title> {{ item.title }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </div>

    <v-dialog v-model="dialog" width="auto" v-if="dialog">
      <v-card width="auto" class="mx-auto" min-width="500">
        <v-card-title>
          <p class="text-uppercase mx-auto">
            {{ item.title }}
          </p>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list v-if="items.length !== 0">
            <v-list-item
              v-for="item in items"
              :key="item.id"
              link
              @click="(pdf = item.location), (pdfDialog = true)"
            >
              <v-list-item-icon>
                <v-icon>mdi-circle-small</v-icon>
              </v-list-item-icon>
              <v-list-item-title> {{ item.name }} </v-list-item-title>
              <v-list-item-action>
                <v-icon color="primary">mdi-file-pdf-box</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <p v-else class="black--text">No documents uploaded yet</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pdfDialog">
      <pdf-viewer :pdf="pdf" />
    </v-dialog>
  </div>
</template>

<script>
import PdfViewer from "../PdfViewer.vue";
import Api from "@/services/Api.js";
export default {
  components: { PdfViewer },
  data() {
    return {
      dialog: false,
      pdfDialog: false,
      budgetItems1: [
        {
          title: "key to budget document",
          type: "key to budget",
          icon: "mdi-script-text-key",
        },
        {
          title: "budget highlights",
          type: "budget highlights",
          icon: "mdi-book-search",
        },
        {
          title: "budget speech",
          type: "budget speech",
          icon: "mdi-book-open",
        },
        {
          title: "finance bill",
          type: "finance bill",
          icon: "mdi-file-chart",
        },
        {
          title: "memorandum explaining the provisions in the financial bill",
          type: "memo for finance bill",
          icon: "mdi-chart-tree",
        },
        {
          title: "implementation of budget announcements",
          type: "announcement implementation",
          icon: "mdi-finance",
        },
      ],
      budgetItems2: [
        {
          title: "budget at a glance",
          icon: "mdi-book",
          type: "budget glance",
        },
        {
          title: "annual financial statement",
          icon: "mdi-script-text",
          type: "annual financial statement",
        },
        {
          title: "receipt budget",
          icon: "mdi-receipt",
          type: "receipt budget",
        },
      ],
      budgetItems3: [
        {
          title: "expenditure profile",
          icon: "mdi-finance",
          type: "expenditure profile",
        },
        {
          title: "expenditure budget",
          icon: "mdi-finance",
          type: "expenditure budget",
        },
        {
          title: "demands for grants",
          icon: "mdi-finance",
          type: "grant demands",
        },
        {
          title: "custom notifications",
          icon: "mdi-finance",
          type: "custom notifications",
        },
      ],
      items: [],
      pdf: "",
      item: null,
    };
  },
  methods: {
    getDocuments(item) {
      this.item = item;
      this.dialog = true;
      Api.getSpecificFileType(item.type).then((res) => {
        this.items = res.data.data.data;
      });
    },
  },
};
</script>

<style></style>
